import React from "react";
import { Link, graphql } from "gatsby";
import ExhibitChildLayout from "../components/layout/layout-exhibit-child";

import Helmet from "react-helmet";

import PageContainer from "../components/layout/page-container";
import PageSection from "../components/layout/page-section";


class SubjectLandingPage extends React.Component {
  constructor(props) {
    super(props);

    this.featuredImage = this.featuredImage.bind(this);
  }

  featuredImage(node) {
    const imageReference = node.featuredImage;
    const imageNodes = this.props.data.allImagesJson.edges.filter(({ node }) => {
      return node.reference === imageReference;
    });

    if (imageNodes.length > 0) {
      const featuredImage = imageNodes[0].node;

      return (
        <img src={`/images/content/${featuredImage.thumbPath}`} alt={featuredImage.alt} />
      );
    }

    return null;
  }

  render() {
    return (
      <ExhibitChildLayout exhibit={this.props.data.exhibit}>
        <Helmet>
            <title>{this.props.data.subject.frontmatter.title}</title>
        </Helmet>
        <PageContainer
          bottomPadding="lg"
        >
          <article>
            <PageSection background="dark-accent">
              <h1 className="page-title">
                {this.props.data.subject.frontmatter.title}
              </h1>
            </PageSection>
            <PageSection background="gray" topPadding="md" bottomPadding="md">
              <div className="subject-narrative-grid">
                <ul className="subject-narrative-grid__grid">
                  {this.props.data.narratives.edges.map(({ node }, idx) => (
                    <li className="subject-narrative-grid__cell" key={idx}>
                      <Link to={`${this.props.data.exhibit.reference}/narratives/${node.reference}`} className="title">
                        {this.featuredImage(node)}
                        {node.title}
                      </Link>
                      <span className="summary">
                        {node.blurb}
                        <Link to={`${this.props.data.exhibit.reference}/narratives/${node.reference}`}>Read more…</Link>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </PageSection>
            <PageSection>
              <div className="subject__content-area" dangerouslySetInnerHTML={{__html: this.props.data.subject.html}} />
            </PageSection>
          </article>
        </PageContainer>
      </ExhibitChildLayout>
    );
  }
}

export default SubjectLandingPage;

export const query = graphql`
  query SubjectQuery($slug: String!, $narratives: [String!]!, $exhibit: String!) {
    exhibit:exhibitsJson(reference: {eq: $exhibit}) {
      reference
      short_title
      subtitle
    },
    subject:markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      frontmatter {
        slug
        title
      }
    },
    narratives:allNarrativesJson(filter: {reference: {in: $narratives}}) {
      edges {
        node {
          featuredImage
          exhibit
          reference
          title
          blurb
        }
      }
    },
    allImagesJson {
      edges {
        node {
          reference
          thumbPath
          alt
        }
      }
    }
  }
`;