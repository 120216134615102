import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

import HelmetWrapper from "./helmet";
import { HamburgerSvg } from "../common/Svgs";
import Footer from "./footer";

import '../../scss/styles.scss';

export default class ExhibitChildLayout extends React.Component {
  mobileNavRef = React.createRef();
  hamburgerRef = React.createRef();

  render() {
    return (
      <StaticQuery
        query={graphql`
          query ExhibitChildLayoutQuery {
            site {
              siteMetadata {
                title
                description
              }
            }
          }
        `}
        render={data => (
          <div>
            <HelmetWrapper site={data.site} />
            <div className="page">
              <div className="header-wrapper">
                <header className="exhibit-child">
                  <div className="header-contents wrapper-width">
                    <div className="text-content">
                      <Link to="/" className="home-block">
                        <span className="label">
                          <span className="cap">LM</span>
                        </span>
                      </Link>
                      <div className="title-block">
                        <Link to={`/${this.props.exhibit.reference}`}>
                          {this.props.exhibit.short_title}: <span className="subtitle">{this.props.exhibit.subtitle}</span>
                        </Link>
                      </div>
                    </div>
                    <nav className="header-nav">
                      <ul>
                        <li><Link to="/notes">Blog</Link></li>
                        <li><Link to="/about">About</Link></li>
                      </ul>
                    </nav>
                    <button
                      className="mobile-nav-launcher"
                      ref={ this.hamburgerRef }
                      onClick={(e) => {
                        const mobileNav = this.mobileNavRef.current;
                        const hamburgerButton = this.hamburgerRef.current;

                        if (mobileNav.classList.contains('expanded')) {
                          hamburgerButton.classList.remove('expanded');
                          mobileNav.classList.remove('expanded');
                        } else {
                          hamburgerButton.classList.add('expanded');
                          mobileNav.classList.add('expanded');
                        }
                      }}
                    >
                      { HamburgerSvg }
                    </button>
                  </div>
                </header>
                <div
                  className="mobile-header"
                  ref={ this.mobileNavRef }
                >
                  <ul>
                    <li><Link to="/notes">Blog</Link></li>
                    <li><Link to="/about">About</Link></li>
                  </ul>
                </div>
              </div>
              {this.props.children}
            </div>
            <Footer site={data.site} />
          </div>
        )}
      />
    )
  }
}
